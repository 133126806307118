import React from 'react'
import Helmet from 'react-helmet'
import withAppInsights from '../utils/AppInsights'

import '../css/style.css'

const Layout = ({ children }) => (
  <>
    <Helmet>
      <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="fda3112e-1dbb-4a80-a2a7-2de431b30d38" type="text/javascript" async></script>
    </Helmet>
    {children}
  </>
)

export default withAppInsights(Layout)
